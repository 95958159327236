import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const GCCLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Brandon Chastant"
    institution="Grand Canyon Clinics"
    referralCode="GCC"
    physicianURL="https://www.grandcanyonclinics.com/"
  />
)

export default GCCLandingPage
